<template>
  <div class="min-h-screen flex flex-column">
    <EventsProgressbar />
    <Header @toggleSidebar="toggleSidebar" />
    <AdminSidebar v-model:visible="isOpenSidebar" @toggleSidebar="toggleSidebar" />
    <div class="pl-3 pt-4 pr-5">
      <router-view />
    </div>
    <Footer class="mt-auto" />
  </div>
</template>

<script setup>
import Header from "@/components/Header";
import AdminSidebar from "@/components/AdminSidebar";
import Footer from "@/components/Footer";
import EventsProgressbar from "@/components/EventsProgressbar";

import { onMounted, ref } from "vue";
import sseService from "@/api/sseService";
import { useUserStore } from "@/store";
import { useToast } from "primevue/usetoast";
import { MATCHING_STATUSES } from "@/utils/сonsts";

const { STARTED, IN_PROGRESS, FINISHED } = MATCHING_STATUSES;

const $userStore = useUserStore();
const $toast = useToast();

let isOpenSidebar = ref(false);
let isMatchingInProgressStatusAlreadyShown = ref(false);
let isSmsInProgressStatusAlreadyShown = ref(false);

const toggleSidebar = () => {
  isOpenSidebar.value = !isOpenSidebar.value;
};

const eventStarted = (summary) =>
  $toast.add({
    severity: "success",
    summary,
    life: 5000,
  });

const eventInProgress = (summary) =>
  $toast.add({
    severity: "info",
    summary,
    life: 5000,
  });
const eventSuccessItemsMessage = (summary, items, detail) =>
  $toast.add({
    severity: "success",
    summary,
    detail: `${items} ${items > 1 ? "were" : "was"} ${detail}`,
    life: 10000,
  });
const eventFailedItemsMessage = (summary, items, detail) =>
  $toast.add({
    severity: "warn",
    summary,
    detail: `${items} ${items > 1 ? "were" : "was"} ${detail}`,
    life: 10000,
  });
let onMessage = ({ data }) => {
  if (!data) {
    return;
  }
  let { type, matchedItems, failedItems, status } = JSON.parse(data);
  switch (type) {
    case "payment":
      paymentUpdateEvent(status, matchedItems, failedItems);
      break;
    case "sms":
      smsUpdateEvent(status, matchedItems, failedItems);
  }
};

const paymentUpdateEvent = (paymentMatchingStatus, matchedPaymentsItems, failedPaymentsItems) => {
  $userStore.paymentMatchingStatus = paymentMatchingStatus;
  switch ($userStore.paymentMatchingStatus) {
    case STARTED:
      eventStarted("Payments matching started");
      break;
    case IN_PROGRESS:
      !isMatchingInProgressStatusAlreadyShown.value &&
        eventInProgress("Payments matching in progress");
      isMatchingInProgressStatusAlreadyShown.value = true;
      break;
    case FINISHED:
      matchedPaymentsItems &&
        eventSuccessItemsMessage(
          "Result of payments matching",
          matchedPaymentsItems,
          "successfully matched"
        );
      failedPaymentsItems &&
        eventFailedItemsMessage(
          "Failed result of payments matching",
          failedPaymentsItems,
          "matching failed"
        );
  }
};

const smsUpdateEvent = (status, sentItems, failedItems) => {
  $userStore.smsSendingStatus = status;
  switch ($userStore.smsSendingStatus) {
    case STARTED:
      eventStarted("SMS sending started");
      break;
    case IN_PROGRESS:
      !isSmsInProgressStatusAlreadyShown.value && eventInProgress("SMS sending in progress");
      isSmsInProgressStatusAlreadyShown.value = true;
      break;
    case FINISHED:
      sentItems &&
        eventSuccessItemsMessage("Result of SMS sending", sentItems, "successfully sent");
      failedItems &&
        eventFailedItemsMessage("Failed result of SMS sending", failedItems, "SMS sending failed");
  }
};

onMounted(() => {
  sseService.start().onmessage = (event) => onMessage(event);
});
</script>
