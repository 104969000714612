<template>
  <transition>
    <div v-if="isShown" class="events-container" :class="{ open: isOpen }">
      <Btn
        :icon="`pi ${toggleIcon}`"
        text
        class="toggle-btn p-button-sm"
        @click="isOpen = !isOpen" />
      <ul class="events-list">
        <li v-if="$userStore.isMatchingStarted">
          Payment matching in progress <ProgressSpinner />
        </li>
        <li v-if="$userStore.isSmsSendingStarted">SMS sending in progress <ProgressSpinner /></li>
      </ul>
    </div>
  </transition>
</template>
<script setup>
import ProgressSpinner from "primevue/progressspinner";
import Btn from "primevue/button";
import { useUserStore } from "@/store";
import { computed, ref } from "vue";

const $userStore = useUserStore();

let isShown = computed(() => $userStore.isSmsSendingStarted || $userStore.isMatchingStarted);
let isOpen = ref(true);

let toggleIcon = computed(() => (isOpen.value ? "pi-chevron-right" : "pi-chevron-left"));
</script>
<style lang="scss">
$events-container-width: 266px;
$toggle-btn-width: 22px;

.events-container {
  max-width: $events-container-width;
  width: 100%;
  position: fixed;
  z-index: 1501;
  display: flex;
  align-items: flex-start;
  transition: 0.2s;
  top: 82px;
  right: calc($toggle-btn-width - $events-container-width);

  &.open {
    right: 0;
  }
  .toggle-btn {
    background: var(--default-bg) !important;
    border: 1px solid var(--surface-border) !important;
    border-right: none !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 2px;
    padding-right: 2px;
    width: 22px;
    height: 40px;
    &:focus {
      box-shadow: none;
    }
  }
  .events-list {
    list-style-type: none;
    margin: 0;
    padding-left: 10px;
    padding-right: 5px;
    font-size: 14px;
    background: var(--default-bg);
    border: 1px solid var(--surface-border);
    border-radius: 4px;
    border-top-left-radius: 0;

    li {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 35px;
    }
    .p-progress-spinner {
      margin-left: 5px;
      margin-right: 0;
      width: 25px;
      height: 25px;
      stroke-width: 2px;
      .p-progress-spinner-circle {
        stroke: var(--primary-color) !important;
        stroke-width: 4px;
      }
    }
  }
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: 0.5s ease;
  top: 0;
}

.v-enter-from,
.v-leave-to {
  transition: 0.5s ease;
  opacity: 0;
}
</style>
